import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Typography, Box } from "@mui/material";

import PortfolioCard from "./components/PortfolioCard";

import { PROJECTS } from "../../data/portfolio-data";

const Portfolio = () => {
  return (
    <>
    <Container id="Portfolio" sx={{ p: 3, marginTop: "80px" }} maxWidth="xl">
      {/* Introductions */}
      <Box sx={{ textAlign: "center" }}>
        <Typography
          component={"span"}
          sx={{
            color: "#6E8378",
            fontSize: { s: 16, md: 18 },
            fontWeight: "bold",
          }}
        >
          VISIT MY PORTFOLIO ENVIORMENTS
        </Typography>
        <Typography
          component={""}
          sx={{
            color: "white",
            py: 1,
            fontSize: {
              xl: "60px",
              lg: "50px",
              md: "40px",
              s: "35px",
              xs: "35px",
            },
          }}
        >
          Portfolio
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={4}
      >
        {PROJECTS.map((project) => (
          <Grid key={project.id} xs={12} lg={3} item>
            <PortfolioCard projectData={project} />
          </Grid>
        ))}
      </Grid>
    </Container>
    <hr style={{border:"1px solid #272727", marginTop:"50px"}} />
    </>
  );
};

export default Portfolio;
