export const PROJECTS = [
  {
    id: "1",
    title: "Good Fellows Coffee",
    description:
      "A small town coffee shop that has a warm welcoming enviroment. Which is exactly what I did when developing and designing the flow of this website in React.",
    websiteUrl: "https://www.goodfellowscoffee.com/",
    image: "GoodFellowsCoffeeImg",
  },
  {
    id: "2",
    title: "Bloom by Nicole Resnick",
    description:
      "Nicole is a successful hair stylists that needed an easy way for people to reach her. I created a website that is extremely user firendly and easy for people to connect with her.",
    websiteUrl: "https://bloomedbeauty.com/",
    image: "BloomImg",
  },
  {
    id: "3",
    title: "Salty Ventures",
    description:
      "An IOS App created to help surfers track their surf sessions and create a record of amazing memories in the water. To also create a community were surfers can connected with another about thier experiences.",
    websiteUrl: "https://apps.apple.com/us/app/salty-ventures/id6469385445",
    image: "SaltyVenturesImg",
  },
  {
    id: "4",
    title: "Parker J Jewelry",
    description: "Where uniqueness meets elegance. Founded by Jessica and Rachel, our passion for fashion, jewelry, and craftsmanship drives everything we do. Specializing in customizable permanent jewelry and charmed pieces, we create timeless treasures that reflect individuality.",
    websiteUrl: "https://parkerj-jewelry.com/",
    image: "ParkerJJewelryImg",
  },
];
