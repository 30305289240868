import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import FeatureCard from "./components/FeatureCard";
import FeaturesData from "./components/FeaturesData";

const Features = () => {
  return (
    <>
    <Container id="Features" sx={{ p: 3, mt: "-110px" }} maxWidth="xl">
      {/* Introductions */}
      <Typography
        sx={{
          color: "#6E8378",
          fontSize: { s: 18, md: 20 },
          fontWeight: "bold",
        }}
      >
        FEATURES
      </Typography>

      <Typography
        component={"span"}
        sx={{
          color: "white",
          py: 1,
          fontSize: {
            xl: "60px",
            lg: "50px",
            md: "40px",
            s: "35px",
            xs: "35px",
          },
        }}
      >
        What I Do
      </Typography>

      {/* 6 Cards */}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        sx={{ p: 2 }}
      >
        {FeaturesData.map((item) => (
          <Grid key={item.id} item xs={12} s={12} md={6} lg={4}>
            <FeatureCard title={item.title} description={item.description} />
          </Grid>
        ))}
      </Grid>
    </Container>
    <hr style={{border:"1px solid #272727", marginTop:"50px"}} />
    </>
  );
};

export default Features;
