import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import BloomImg from "../../../images/PortfolioImages/BloombyNicole.png";
import GoodFellowsCoffeeImg from "../../../images/PortfolioImages/GoodFellowsCoffee.png";
import SaltyVenturesImg from "../../../images/PortfolioImages/SaltyVentures.png";
import ParkerJJewelryImg from "../../../images/PortfolioImages/ParkerJJewelry.png"

const imgNameFun = (name) => {
  switch (name) {
    case "BloomImg":
      return BloomImg;
    case "GoodFellowsCoffeeImg":
      return GoodFellowsCoffeeImg;
    case "SaltyVenturesImg":
      return SaltyVenturesImg;
    case "ParkerJJewelryImg":
      return ParkerJJewelryImg;
    default:
      return "";
  }
};

export default function PortfolioCard({ projectData }) {

  return (
    <Card sx={{ width: "auto", borderRadius:"15px"}}>
      <CardMedia
        component="img"
        alt={projectData.title}
        height="350"
        image={imgNameFun(projectData.image)}
      />
      <CardContent>
        <Typography
          sx={{
            color: "#6E8378",
          }}
          gutterBottom
          variant="h5"
          component="div"
        >
          {projectData.title}
        </Typography>
        <Typography sx={{height:"175px"}} variant="body1" color="text.secondary">
          {projectData.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" sx={{color:"#6E8378"}} href={projectData.websiteUrl} target="_blank">
          Visit Site
        </Button>
      </CardActions>
    </Card>
  );
}
